import React, { useState } from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { signUpBanner } from "../utils/contactInformation";
import Banner from "../components/banner/banner";
import Button from '../components/button/button';
import '../styles/contact.scss';
import cars from "../assets/car-bike.png"




// markup
const SignUp = () => {

    const [msgError, setMsgError] = useState(false);

    const [signUpMessage, setSignUpMessage] = useState({
        title: '',
        firstName: '',
        surname: '',
        phone: '',
        email: '',
        postcode: '',
        dueDate: '',
        about: '',
    });

    const [error, setError] = useState({
        titleError: '',
        firstNameError: '',
        surnameError: '',
        phoneError: '',
        emailError: '',
        postcodeError: '',
        dueDateError: '',
        aboutError: '',
    });

    const validate = async (e) => {

        let titleError = "";
        let firstNameError = "";
        let surnameError = "";
        let phoneError = "";
        let emailError = "";
        let postcodeError = "";
        let dueDateError = "";
        let aboutError = "";
        let isNotValid = false;


        if (signUpMessage.title === "") {
            titleError = "This field is required.";
            isNotValid = true;
        }
        if (signUpMessage.firstName === "") {
            firstNameError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.surname === "") {
            surnameError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.phone === "") {
            phoneError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.email === "") {
            emailError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.postcode === "") {
            postcodeError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.dueDate === "") {
            dueDateError = "This field is required.";
            isNotValid = true;

        }
        if (signUpMessage.about === "") {
            aboutError = "This field is required.";
            isNotValid = true;

        }

        setError({
            ...error, titleError: titleError, firstNameError: firstNameError, surnameError: surnameError, phoneError: phoneError, emailError: emailError,
            postcodeError: postcodeError, dueDateError: dueDateError, aboutError: aboutError
        });
        return isNotValid;

    }

    const handleInput = async (event) => {

        event.persist();
        setSignUpMessage({ ...signUpMessage, [event.target.name]: event.target.value })

    }

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {

        event.preventDefault();

        let value = await validate();
        setMsgError(value);

        setLoading(true)

        var body = {
            Token: 'phARMocDrnKoPgKl0zcjMOhD8ZeaWrnn7',
            FormData: JSON.stringify(signUpMessage)
        };
        //console.log(body);
        // !value && fetch("https://api.phaeria.com/api/home/SendEmailNotification", {

        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: {

        //         'Content-Type': 'application/json',
        //         'redirect': 'follow',
        //         'mode': 'no-cors',
        //     }
        // }).then(response => response.text().then(function (responseText) {
        //     setLoading(false);
        //     if (response.status === 200) {
        //         setMessage("Thanks for your message!")
        //     }
        //     else {
        //         setMessage("Something gone wrong...Please try again later!")
        //     }
        // }))

        let spamDomains = ["mailinator", "aosod.com", "guerrillamail", "spam4.me", "pokemail.net", "sharklasers.com", "tcwlx.com", "yopmail.com", "nfstripss.com", "maildrop.cc"];
        let isSpam = 0
        spamDomains.forEach(function (domain, index) {
            if (JSON.stringify(signUpMessage).includes(domain)) {
                isSpam = 1;
            }
        });

        if (isSpam == 0 && !value) {
            fetch("https://api.phaeria.com/api/home/SendEmailNotification", {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response =>
                response.text().then(function (responseText) {
                    console.log("response text: " + responseText);
                    setLoading(false);
                    if (response.status === 200) {
                        setMessage("Thanks for your message!")
                    }
                    else {
                        setMessage("Something gone wrong...Please try again later!")
                    }
                })
            )
        } else {
            setMessage("❗ Your message cannot be sent. Please try again with another email address.")
            console.log("Spam.")
        }
    }

    return (
        <Layout SEOTitle={"Sign Up To FML | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={signUpBanner} />

            <div className="contact-grid">
                <div className="uk-grid grid-container" uk-grid="true">

                    <div className="uk-width-1-2 contact-column half-width">
                        <div className="uk-card uk-card-default uk-card-body" id="contact-info">
                            <p>Complete the form and we will email your documents to you, which will consist of a covering letter, the terms and conditions leaflet and an accident checklist.</p>
                            <p>Remember, there are no fees to join and there is no need to renew your membership each year, so you need never get charged for a motor legal protection policy by your insurer or broker ever again.</p>
                            <p>Membership is free and open to motorists in England, Scotland & Wales only.</p>
                            <p>Membership is for car & van drivers as well as motorcyclists. You only need to join once and we do not need details of your vehicles as the membership is attached to you, rather than individual vehicles.</p>
                            <div className="image-animation" >
                                <img src={cars} alt="image-animation" />
                            </div>
                        </div>
                    </div>

                    <div className="uk-width-1-2 half-width">
                        <div className="uk-card uk-card-default uk-card-body contact-second-column" id="contact-column-form">
                            <p className="padding-top"><i>Please only click the Register button once.</i></p>
                            <p><i><b>We will not disclose your personal data to any third parties</b></i></p>
                            <form className="uk-grid-small uk-grid" uk-grid="true"
                                onSubmit={handleSubmit}
                            >
                                <div className="uk-width-1-1@s margin-top">
                                    {/* <label className="uk-form-label" htmlFor="form-stacked-text"></label>
                                    <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={} name="" /> */}
                                    <select name="title" className={`uk-input ${error.titleError !== "" ? 'red-border' : ''}`} value={signUpMessage.title} onChange={handleInput}>
                                        <option value="" disabled selected>Title</option>
                                        <option value={'mr'}>Mr</option>
                                        <option value={'mss'}>Mrs</option>
                                        <option value={'miss'}>Miss</option>
                                        <option value={'ms'}>Ms</option>
                                    </select>
                                </div>

                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">First Name</label>
                                    <input className={`uk-input ${error.firstNameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={signUpMessage.firstName} name="firstName" />
                                </div>

                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Surname</label>
                                    <input className={`uk-input ${error.surnameError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={signUpMessage.surname} name="surname" />
                                </div>

                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Telephone</label>
                                    <input className={`uk-input ${error.phoneError !== "" ? 'red-border' : ''}`} type="tel" id="form-stacked-text" onChange={handleInput} value={signUpMessage.phone} name="phone" />
                                </div>

                                <div className="uk-width-1-2@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Email Address</label>
                                    <input className={`uk-input ${error.emailError !== "" ? 'red-border' : ''}`} type="email" id="form-stacked-text" onChange={handleInput} value={signUpMessage.email} name="email" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    <label className="uk-form-label" htmlFor="form-stacked-text">Postcode</label>
                                    <input className={`uk-input ${error.postcodeError !== "" ? 'red-border' : ''}`} type="text" id="form-stacked-text" onChange={handleInput} value={signUpMessage.postcode} name="postcode" />
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    {/* <label className="uk-form-label" htmlFor="form-stacked-text"></label>
                                    <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={} name="" /> */}
                                    <select name="dueDate" className={`uk-input ${error.dueDateError !== "" ? 'red-border' : ''}`} value={signUpMessage.dueDate} onChange={handleInput}>
                                        <option value="" disabled selected>When is your insurance renewal due?</option>
                                        <option value={'Jan'}>Jan</option>
                                        <option value={'Feb'}>Feb</option>
                                        <option value={'Mar'}>Mar</option>
                                        <option value={'Apr'}>Apr</option>
                                        <option value={'May'}>May</option>
                                        <option value={'Jun'}>Jun</option>
                                        <option value={'Jul'}>Jul</option>
                                        <option value={'Aug'}>Aug</option>
                                        <option value={'Sep'}>Sep</option>
                                        <option value={'Oct'}>Oct</option>
                                        <option value={'Nov'}>Nov</option>
                                        <option value={'Dec'}>Dec</option>
                                    </select>
                                </div>

                                <div className="uk-width-1-1@s margin-top">
                                    {/* <label className="uk-form-label" htmlFor="form-stacked-text"></label>
                                    <input className="uk-input" type="text" id="form-stacked-text" onChange={handleInput} value={} name="" /> */}
                                    <select name="about" className={`uk-input ${error.aboutError !== "" ? 'red-border' : ''}`} value={signUpMessage.about} onChange={handleInput}>
                                        <option value="" disabled selected>How did you hear about us?</option>
                                        <option value={'Facebook'}>Facebook</option>
                                        <option value={'Friend of family'}>Friend of family</option>
                                        <option value={'Google'}>Google</option>
                                        <option value={'Money'}>Money</option>
                                        <option value={'Newspaper'}>Newspaper</option>
                                        <option value={'Radio'}>Radio</option>
                                        <option value={'Other'}>Other</option>
                                    </select>
                                </div>


                                <div className="uk-width-1-1@s margin-top">
                                    <div className="margin-top contact-send-btn">
                                        <Button
                                            id={"sign-up-submit"}
                                            btnClass={"primary"}
                                            btnType={"primary"}
                                            label={`${loading == false ? 'Submit' : 'Loading...'}`}
                                            isSubmit={"true"}
                                            additionalClass={"full-width-btn"}
                                        ></Button>
                                    </div>
                                </div>

                                {
                                    msgError ? <p className="error-message">One or more fields have an error. Please check and try again.</p> : ""
                                }
                                {
                                    message ? <p className="error-message">{message}</p> : ""
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    )

}

export default SignUp